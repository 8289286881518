import React from 'react';

const DictLink = ({ search, children }) => (
  <>
    {search && (
      <a
        href={`https://app.kumalearn.com/dictionary/${encodeURIComponent(search)}`}
        target="_blank"
        rel="noreferrer nofollow"
        className="underline"
      >
        {children || search}
      </a>
    )}
    {!search && (
      <a
        href="https://app.kumalearn.com/dictionary"
        target="_blank"
        rel="noreferrer nofollow"
        className="underline"
      >
        {children}
      </a>
    )}
  </>
);

export default DictLink;
