import React from 'react';
import { Link } from 'gatsby';
import Container from '../components/shared/Container';
import SEO from '../components/shared/SEO';
import mario from '../images/mario.png';
import logo from '../images/logo_circle.svg';
import SubscriptionForm from '../components/shared/SubscriptionForm';
import GiveFeedback from '../components/shared/GiveFeedback';
import DictLink from '../components/shared/DictLink';

const SignUpToNewsletter = () => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto px-4 pt-6 pb-12 sm:px-6 lg:pt-0 lg:pb-24 lg:px-8">
      <div className="px-6 py-6 bg-green-700 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
        <div className="xl:w-0 xl:flex-1">
          <h2 className="text-2xl font-extrabold tracking-tight text-white sm:text-3xl">
            Want to be part of the Kuma Learn journey?
          </h2>
          <p className="mt-3 max-w-3xl text-lg leading-6 text-green-200">
            Sign up to the newsletter and get updates when new learning resources become available.
          </p>
        </div>
        <div className="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
          <SubscriptionForm darkBackground />
        </div>
      </div>
    </div>
  </div>
);

const About = () => (
  <div>
    <SEO title="Kuma Learn - About - Learn Japanese Visually" description="Kuma Learn was created by Mario and Tugboat Coding to make learning Japanese easier and more fun! Mario is the creator, editor and engineer behind Kuma Learn." />
    <GiveFeedback />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <h1 className="text-3xl font-extrabold tracking-tight sm:text-4xl">About</h1>
            <p className="text-xl text-gray-500">
              Getting started with learning Japanese can seem a tall order,
              with so many unfamiliar words!
            </p>
            <p className="text-xl text-gray-500">
              There are great resources to learn from, including <a className="underline" href="https://wanikani.com" target="_blank" rel="noreferrer nofollow">WaniKani</a>, <a className="underline" href="https://duolingo.com" target="_blank" rel="noreferrer nofollow">DuoLingo</a> and <a className="underline" href="http://genki.japantimes.co.jp/index_en" target="_blank" rel="noreferrer nofollow">Genki</a>.
              These resources help you learn using various approaches, such as <em>audial</em> and
              {' '}<em>kinaesthetic</em> techniques (e.g. games).
              Kuma Learn started with a <em>visual</em> approach to learning Japanese vocabulary:
              a <DictLink>Japanese-English picture dictionary</DictLink> as well
              as <Link className="underline" to="/ebooks">eBooks containing hand-picked words with pictures</Link>.
              Some words lend themselves well to visual presentation and that&apos;s why we&apos;re
              creating learning resources and content with pictures.
              Kuma Learn also offers a <Link href="https://app.kumalearn.com/srs?utm_medium=web&utm_source=www&utm_campaign=about" className="underline">Spaced Repetition System (SRS)</Link> so that you can learn vocab and kanji efficiently.
            </p>
            <p className="text-xl text-gray-500">
              Kuma Learn is a passion project created by Mario in his spare time
              to make learning Japanese easier and more fun!
            </p>
          </div>
          <ul class="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:max-w-5xl">
            <li>
              <div className="space-y-6">
                <img className="mx-auto h-24 w-24 rounded-full xl:w-32 xl:h-32" src={mario} alt="Mario Hayashi, Kuma Learn"/>
                <div className="space-y-2">
                  <div className="text-lg leading-6 font-medium space-y-1">
                    <h3>Mario Hayashi</h3>
                    <p className="text-green-600 mb-1">Creator of Kuma Learn</p>
                  </div>
                  <ul className="flex justify-center space-x-5">
                    <li>
                      <a href="https://twitter.com/logicalicy" target="_blank" rel="nofollow noreferrer" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Twitter</span>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                          <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div className="space-y-6">
                <div className="flex justify-center items-center">
                  <img className="h-24 w-24 xl:w-32 xl:h-32" src={logo} alt="Kuma Learn"/>
                </div>
                <div className="space-y-2">
                  <div className="text-lg leading-6 font-medium space-y-1">
                    <h3>Kuma</h3>
                    <p className="text-green-600 pb-1">Your Friendly Guide</p>
                    <div className="flex justify-center space-x-6">
                      <a href="https://twitter.com/KumaLearn" target="_blank" rel="noreferrer nofollow" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Twitter</span>
                        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                      </a>
                      <a href="https://www.instagram.com/kumalearn/" target="_blank" rel="noreferrer nofollow" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Instagram</span>
                        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                          <path
                            fillRule="evenodd"
                            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <SignUpToNewsletter />
    </div>
  </div>
);

const AboutPage = () => (
  <Container>
    <About />
  </Container>
);

export default AboutPage;
